import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./App";
import {
  useLocation,
  useHistory,
  useParams,
  Link,
  Redirect,
} from "react-router-dom";
import "./styles.css";
import { validateCase } from "./functions/validation";

import { Autocomplete, Button, TextField } from "@mui/material";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { createFilterOptions } from "@mui/material/Autocomplete";
import JSONPretty from "react-json-pretty";
import DisplayCaseDataTable from "./components/DisplayCaseDataTable";
import PlanningCaseInfo from "./components/PlanningCaseInfo";

import { v4 as uuidv4 } from "uuid";

import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  ADD_CASE,
  ADD_CASE_REG,
  AMEND_CASE_REG,
  VIEW_REG_CASES,
  VIEW_REGISTRANT_INFO,
  EDIT_REGISTRANT,
  VIEW_CASES,
} from "./Queries";

// Modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

/*const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});*/

export function SelectCase(props) {
  const userInfo = useContext(UserContext);
  const params = useParams();

  const caseId = params.caseId ? params.caseId : ""; //todo: figure out how to have a separate route when they select the case

  /* State */
  const [inputCaseNbr, setInputCaseNbr] = useState(""); //The actual case number input by user
  const [autocompleteData, setAutocompleteData] = useState([{ caseNbr: "" }]); //Autocomplete Case Numbers
  const [selectedCaseId, setSelectedCaseId] = useState(caseId); //The id of the actual case number selected by user.
  const [selectedCaseData, setSelectedCaseData] = useState({}); // selected Planning Case data;
  const [loading, setLoading] = useState(""); // loading state
  const [regUserId, setRegUserId] = useState(null);
  const [newRegUserId, setNewRegUserId] = useState(
    localStorage.getItem("newRegUserId") || ""
  );
  const [speFlg, setSpeFlg] = useState(null);

  /* Hooks */
  const location = useLocation();
  const history = useHistory();

  /* Modal */

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const getContactInfos =
    (selectedCaseData.contactInformation &&
      Object.values(
        selectedCaseData.contactInformation
          .map((contact) => contact.emailAddrNm)
          .filter((value) => value != null)
      )) ||
    [];

  /* Data */
  const [addCase] = useMutation(ADD_CASE);
  const [addCaseReg, { loading: addCaseRegLoading, error: addCaseRegError }] =
    useMutation(ADD_CASE_REG, {
      onCompleted: (newData) => {
        history.push({
          pathname: `/Register/${selectedCaseData.caseId}/Description`,
        });
      },
    });
  const [amendCaseReg] = useMutation(AMEND_CASE_REG, {
    onCompleted: (newData) => {
      history.push({
        pathname: `/Amend/${selectedCaseData.caseId}/Description`,
      });
    },
  });

  const viewCaseReg = useQuery(VIEW_REG_CASES, {
    variables: { caseID: `${selectedCaseData.caseId}` },
    fetchPolicy: "network-only",
  });

  // When case is selected, will prompt query of database.
  const viewCase = useQuery(VIEW_CASES, {
    variables: { caseID: `${selectedCaseData.caseId}` },
    fetchPolicy: "network-only",
    onCompleted: (newData) => {
      // This is triggered when selectedCaseData is set after the planning_case record is inserted.
      // The SPEFlg is pulled from a virtual field on the table and is set to 1 if the case is considered a "Significant Planning Entitlement"
      const speFlg = newData?.getCaseInfo?.SPEFlg
        ? newData.getCaseInfo.SPEFlg !== 0 &&
          newData.getCaseInfo.SPEFlg !== null
          ? 1
          : 0
        : 0;

      setSpeFlg(speFlg);
    },
  });

  const registrantEmail = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.user_id
    : null;

  const registrantInfo = useQuery(VIEW_REGISTRANT_INFO, {
    variables: { userEmail: getContactInfos },
    fetchPolicy: "network-only",
  });
  const getRegistrantInfo = () => {
    if (!registrantInfo.loading) {
      const contactInfos = registrantInfo.data.getRegistrantInfo.map((obj) => {
        return (
          <RegistrantRadioButtons
            email={obj.user_id}
            firstName={obj.user_fnm}
            lastName={obj.user_lnm}
          />
        );
      });

      return contactInfos;
    }
  };

  const [editRegistrant, { data }] = useMutation(EDIT_REGISTRANT);

  const handleOptionChange = (event) => {
    setNewRegUserId(event.target.value);
    localStorage.setItem("newRegUserId", event.target.value);
  };

  const registrantInfoChange = useQuery(VIEW_REGISTRANT_INFO, {
    variables: { userEmail: newRegUserId },
    fetchPolicy: "network-only",
  });

  const getCaseData = async (getCaseId) => {
    let getCaseURL = `${process.env.REACT_APP_WWWROOT}/cfcs/services/planningapis.cfc?method=GetCaseInfo&caseId=${getCaseId}`;

    const response = await axios.get(getCaseURL);
    const data = response.data;

    //initialize applicants and owners
    data.applicants = [];
    data.owners = [];
    data.representatives = [];

    if (data.contactInformation) {
      //loop through contact information and set concatenated address and phone.
      data.contactInformation.map((contact) => {
        /* build one street address to edit */
        const strNmFull = `${setFalsyToBlank(contact.strNbr)} ${setFalsyToBlank(
          contact.strFracNbr
        )} ${setFalsyToBlank(contact.strNm)} ${setFalsyToBlank(
          contact.strSfxCd
        )} ${setFalsyToBlank(contact.strDirCd)} ${setFalsyToBlank(
          contact.strUnitTypCd
        )} ${setFalsyToBlank(contact.strUnitNbr)}`;
        contact.strNmFull = strNmFull.trim().replace(/  +/g, " ");

        /* build one phone number to edit */
        let phoneNumberFull = contact.contactPhone.length
          ? `${
              contact.contactPhone[0].telAreaCd
            } ${contact.contactPhone[0].telNbr.substr(
              0,
              3
            )}-${contact.contactPhone[0].telNbr.substr(3)}`
          : "";
        contact.phoneNumberFull = phoneNumberFull;

        // Remove SAME AS APPLICANT and SAME AS OWNER
        if (contact.coBusnNm != null) {
          if (
            contact.coBusnNm.trim() == "SAME AS APPLICANT" ||
            contact.coBusnNm.trim() == "SAME AS OWNER"
          ) {
            contact.coBusnNm = "";
          }
        }

        if (contact.frstNm != null) {
          if (
            contact.frstNm.trim() == "SAME AS APPLICANT" ||
            contact.frstNm.trim() == "SAME AS OWNER"
          ) {
            contact.frstNm = "";
          }
        }

        if (contact.lstNm != null) {
          if (
            contact.lstNm.trim() == "SAME AS APPLICANT" ||
            contact.lstNm.trim() == "SAME AS OWNER"
          ) {
            contact.lstNm = "";
          }
        }

        /* Initialize other vars */
        contact.indOrgFlg = "";
        contact.principalFlg = "";
        contact.title = "";
        contact.cntctUuid = uuidv4(); // generate unique id for each contact.
        contact.principalOfUuid = ""; // Used for linking principals to organization
        contact.principalOfCoBusnNm = ""; // Used for linking principals to organization
        return contact;
      });

      // filter out applicants
      data.applicants = data.contactInformation.filter(
        (contact) => contact.cntctTypCd === "A"
      );

      // filter out owners
      data.owners = data.contactInformation.filter(
        (contact) => contact.cntctTypCd === "OWNER"
      );

      // filter out representatives
      data.representatives = data.contactInformation.filter(
        (contact) => contact.cntctTypCd === "R"
      );
    }

    /* On test allow any user to register case */
    if (process.env.REACT_APP_TEST_MODE == "true") {
      data.contactInformation[0].emailAddrNm = userInfo.email;
    }
    addCase({
      variables: {
        caseID: `${getCaseId}`,
        caseData: JSON.stringify(data),
      },
    }); // update case record when pulled up by user

    //Sets selected Case Data. This will trigger viewCase query to run and speFlg to be set.
    setSelectedCaseData(data);
  };

  const handleChangeRegistrant = (e) => {
    console.log(e);
    e.preventDefault();

    const registrantFirstName =
      registrantInfoChange.data.getRegistrantInfo[0].user_fnm;
    const registrantLastName =
      registrantInfoChange.data.getRegistrantInfo[0].user_lnm;

    // Perform actions based on selected option
    editRegistrant({
      variables: {
        USER_ID: newRegUserId,
        FILER_FNAME: registrantFirstName,
        FILER_LNAME: registrantLastName,
        DOCUMENT_ID: documentId,
      },
    });
    setRegUserId(newRegUserId);

    handleClose();
  };

  function RegistrantRadioButtons(props) {
    return (
      <div>
        <input
          type="radio"
          name={`applicant-${props.email}`}
          value={props.email}
          checked={newRegUserId === props.email}
          onChange={handleOptionChange}
        />
        {" " +
          props.firstName +
          " " +
          props.lastName +
          " (" +
          props.email +
          ")"}
      </div>
    );
  }

  let documentStatusId = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.document_status_id
    : null;
  let amendNum = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.amendNum
    : null;
  let filerFname = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.filer_fname
    : null;
  let filerLname = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.filer_lname
    : null;
  let documentId = viewCaseReg.loading
    ? null
    : viewCaseReg.data.getCaseRegInfo
    ? viewCaseReg.data.getCaseRegInfo.document_id
    : null;

  const setFalsyToBlank = (thisString) => (thisString ? thisString.trim() : "");

  /* Handler for when User selects the case */
  const handleSelectCase = (e, d) => {
    /* re-initialize state */
    setSelectedCaseData({});

    /* grab caseData from API and set to session */
    if (d) {
      setSelectedCaseId(d.caseId);
      getCaseData(d.caseId);
    }
  };

  /* if caseId was passed in through params, then grab it. */
  if (caseId) {
    handleSelectCase(caseId);
  }

  /* Handler for when the user types Case Number in input box */
  const handleCaseNbrChange = (e, d) => {
    setInputCaseNbr(d);

    // Allows copy paste
    let searchCaseURL = `${process.env.REACT_APP_WWWROOT}/cfcs/services/planningapis.cfc?method=searchCaseNumber&caseNo=${d}`;

    const getData = async () => {
      setLoading(true);
      const response = await axios.get(searchCaseURL);
      setAutocompleteData(response.data); //todo: trim these caseNumbers
      setLoading(false);
    };

    getData();
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 1000,
  });

  /* Handler for when user registers the case. */
  const handleRegisterCase = () => {
    addCaseReg({
      variables: {
        caseID: `${selectedCaseData.caseId}`,
        caseRegistrationData: JSON.stringify(selectedCaseData),
        userEmail: userInfo.email,
        userFrstNm: userInfo.given_name,
        userLstNm: userInfo.family_name,
      },
    });

    /*redirect is in the addCaseReg onCompleted handler */
  };

  const handleCompleteRegistration = () => {
    let path = `/Register/${selectedCaseData.caseId}/Description`;
    history.push({
      pathname: path,
    });
  };

  const handleAmendRegistration = () => {
    amendCaseReg({
      variables: {
        caseID: `${selectedCaseData.caseId}`,
        userEmail: userInfo.email,
        userFrstNm: userInfo.given_name,
        userLstNm: userInfo.family_name,
      },
    });

    /*redirect is in the amendCaseReg onCompleted handler */
  };

  /* Check for existence of reg record (future: check actual document status) */
  const getCaseStatus = () => {
    switch (documentStatusId) {
      case 0:
        return `This case is being ${amendNum > 0 ? "amended" : "registered"}.`;
      case 1:
        return `This case is being ${amendNum > 0 ? "amended" : "registered"}.`;
      case 2:
        return `This case was registered${amendNum > 0 ? " and amended" : ""}.`;
      case 3:
        return `This case was registered${amendNum > 0 ? " and amended" : ""}.`;
      default:
        return "This case has not been registered.";
    }
  };

  const resetCaseForm = () => {
    setInputCaseNbr("");
    setSelectedCaseId("");
    setAutocompleteData([]);
    setSelectedCaseData({});
  };

  const isUserAuthorizedforCase = (caseData) => {
    /* Look for email on owners, applicants and representatives */
    let contactMatch = null;
    if (Boolean(caseData) && Boolean(userInfo.email)) {
      contactMatch = caseData.contactInformation.find((contact) => {
        let contactMatchesUser = !Boolean(contact.emailAddrNm)
          ? null
          : contact.emailAddrNm.toUpperCase().trim() ===
            userInfo.email.toUpperCase().trim();
        if (contactMatchesUser) {
          return contact;
        }
      });
    }
    return Boolean(contactMatch);
  };

  /* uses SPEFlg virtual field on planning_cases table to avaoid redundant logic.
  const isCaseSignificant = (caseData) => {
    if (caseData.caseNbr){
      let myArray = caseData.caseNbr.split("-") ;

      // Check for existance of these codes anywhere in casNbr 
      let suffix = myArray
      let suffixRegex = /\b(?:DB|DA|GPA|HD|O|SN|SPR|SP|TT|TDR|TOC|VTT|VZC|ZC)\b/gi
      let suffixOutput = suffixRegex.test(suffix);

      // Get the first word from array (prefix)
      let prefix = myArray[0]

      // Get the last word from array (suffix) if it is ZV
      let suffixRegexZV = /\bZV\b/g;
      let output = suffixRegexZV.test(suffix);

      // Exclude anything that is prefixed with ADM- or PAR- from being identified as an SPE
      if (prefix.substring(0, 3) == "ADM" || prefix.substring(0, 3) == "PAR"){
        return false;
      }
      

      if (suffixOutput == true || caseData.processingUnitCode == "MP" || caseData.processingUnitName == "Major Projects" || 
        prefix.substring(0, 3) == "APC" && output == true || prefix == "CPC" && output == true){
        //console.log("Condition: ", true)
        return true;
      }
      else{
        //console.log("Condition: ", false)
        return false;
      }
    }
    
  }*/

  return (
    userInfo && (
      <>
        {!selectedCaseId && (
          <div className="caseNbr">
            <h4>Select Case</h4>
            <p>
              Enter the case number you received from the Los Angeles City
              Planning Department.
            </p>
            <div className="formGroup">
              <Autocomplete
                disablePortal
                id="caseNbr-autocomplete"
                options={autocompleteData}
                getOptionLabel={(option) => option.caseNbr}
                onInputChange={handleCaseNbrChange}
                onChange={handleSelectCase}
                filterOptions={filterOptions}
                noOptionsText={
                  loading
                    ? "Loading Cases..."
                    : inputCaseNbr.length < 7
                    ? "You must type the first 7 characters of Case Number"
                    : "No Matching Case Numbers"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="caseNbr"
                    label="Case Number"
                    className="caseNbr"
                    value={inputCaseNbr}
                  />
                )}
              />
            </div>
          </div>
        )}
        {selectedCaseId && Boolean(selectedCaseData) && (
          <>
            <DisplayCaseDataTable caseData={selectedCaseData} />
            <br />
            {
              /*Is significant planning entitlement and registration in progress, show Yes/No buttons (TODO: Need to check for email match here) */

              //isCaseSignificant(selectedCaseData) ( now uses SPEFlg virtual field on planning_cases table to avoid redundant logic)
              speFlg == 1 ? (
                <>
                  <p>
                    This case is a Significant Planning Entitlement and must be
                    registered.
                  </p>
                  <p>{getCaseStatus()}</p>
                  {Boolean(regUserId) && regUserId !== userInfo.email ? (
                    /* If another user has already registered the case it can only be updated/amended by that user  */
                    <p>
                      User {regUserId}{" "}
                      {documentStatusId > 1
                        ? "has already registered"
                        : "is registering"}{" "}
                      this case.
                    </p>
                  ) : /* check if user email is listed on the planning case data */
                  isUserAuthorizedforCase(selectedCaseData) ? (
                    <>
                      {documentStatusId > 1 ? (
                        <Button
                          className="yellowButton"
                          onClick={() => handleAmendRegistration()}
                        >
                          Amend This Case Registration
                        </Button>
                      ) : documentStatusId == 0 || documentStatusId == 1 ? (
                        <Button
                          className="yellowButton"
                          onClick={() => handleCompleteRegistration()}
                        >
                          Complete This{" "}
                          {amendNum > 0 ? "Amendment" : "Registration"}
                        </Button>
                      ) : (
                        <Button
                          className="yellowButton"
                          onClick={() => handleRegisterCase()}
                        >
                          Register This Case
                        </Button>
                      )}
                    </>
                  ) : (
                    <p>
                      User {userInfo.email} is not authorized to register this
                      case.
                    </p>
                  )}
                </>
              ) : (
                <p>
                  This Case is NOT a Significant Planning Entitlement and does
                  not need to be registered.
                </p>
              )
            }
            <Button className="greyButton" onClick={() => resetCaseForm()}>
              Register another Case
            </Button>
            {userInfo.userSecLvl == "AD" &&
              (documentStatusId == 0 || documentStatusId == 1) && (
                <>
                  <Button className="yellowButton" onClick={handleOpen}>
                    Change Registrant
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <h3>Change Registrant to:</h3>
                      <form onSubmit={handleChangeRegistrant}>
                        {getRegistrantInfo()}
                        <br />
                        <div>
                          <Button className="yellowButton" type="submit">
                            Submit
                          </Button>
                          <Button
                            className="greyButton"
                            onClick={() => handleClose()}
                          >
                            Close
                          </Button>
                        </div>
                      </form>
                    </Box>
                  </Modal>
                </>
              )}
            {addCaseRegLoading && (
              <>
                <p>Loading...</p>
              </>
            )}
            {addCaseRegError && (
              <>
                <p>Error registering Case...</p>
                <JSONPretty
                  id="json-pretty"
                  data={addCaseRegError}
                ></JSONPretty>
              </>
            )}
          </>
        )}
        <PlanningCaseInfo />

        {/* Debug switch */}
        {/* process.env.REACT_APP_TEST_MODE && <JSONPretty id="json-pretty" data={selectedCaseData}></JSONPretty> */}
      </>
    )
  );
}

export default SelectCase;
